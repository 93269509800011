import { GL_CARDS, GL_COLORS, GL_HAMBURGUER_MENU, GL_LOADING, GLCard, GLEmptyList, GLList, GLPaginationComponent, GLTypography } from "@group-link-one/grouplink-components";

import EmptyListImage from "../../../../images/MessageCenter/empty_art_light.svg";
import { SelectTemplateCards } from "./Cards/SelectTemplateCards";
import { ButtonChangeGrid, SelectTemplateContainer, SelectTemplateFiltersBottom } from "./SelectTemplateStyle";
import { IPP_SELECT_TEMPLATES, useSelectTemplate } from "./useSelectTemplate";


export function SelectTemplate() {
  const {
    tabs,
    setButtonGridColor,
    selectTemplateActions,
    selectTemplateState,
    activeTab,
    currentPage,
    handleOnPaginate,
    templates,
    templatesIsLoading
  } = useSelectTemplate();

  return (
    <SelectTemplateContainer>
      <div className="select-template-header">
        <GLTypography
          text="Start by selecting a message template to send"
          as="p"
          color="FONT_COLOR"
          fontSize={4}

        />
      </div>

      <div className="select-template-body">
        {templates && templates.length === 0 ? (
          <div className="online-campaign-empty-list">
            <GLEmptyList
              title="No templates created yet"
              description="Create a new project to get started."
              icon={<img src={EmptyListImage} alt="" />}
              hasBorder={true}
            />
          </div>
        ) : (
          <GLList
            hasFixedHeaderAnimation={false}
            listName="All Templates"
            content={<SelectTemplateCards templates={templates} />}
            type={selectTemplateState.typeList}
            tabs={tabs}
            isLoading={templatesIsLoading}
            tabActive={activeTab?.name || ""}
            filters={
              <GLCard.Column gap={30}>
                <ButtonChangeGrid
                  onClick={() => {
                    selectTemplateActions.setTypeList("grid");
                  }}
                >
                  <GL_CARDS
                    size={20}
                    fill={setButtonGridColor("grid")}
                  />
                </ButtonChangeGrid>

                <ButtonChangeGrid
                  onClick={() => {
                    selectTemplateActions.setTypeList("list");
                  }}
                >
                  <GL_HAMBURGUER_MENU
                    size={20}
                    fill={setButtonGridColor("list")}
                  />
                </ButtonChangeGrid>
              </GLCard.Column>
            }
            filtersBottom={
              <SelectTemplateFiltersBottom>
                <button className="select-template-refresh-list">
                  <GL_LOADING fill={GL_COLORS.ACCENT_COLOR} />
                  <GLTypography
                    text="Refresh list"
                    as="span"
                    color="ACCENT_COLOR"
                    fontSize={4}
                  />
                </button>

                <GLPaginationComponent
                  itemsPerPage={IPP_SELECT_TEMPLATES}
                  nextPage={handleOnPaginate}
                  previousPage={handleOnPaginate}
                  // totalItems={activeTab?.count || 0}
                  totalItems={10}
                  currentPage={currentPage}
                />
              </SelectTemplateFiltersBottom>
            }
          />
        )}

      </div>
    </SelectTemplateContainer>
  );
}
