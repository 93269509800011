import { GLCard, GLTypography } from "@group-link-one/grouplink-components"
import { format } from "date-fns";

import { GetNotificationsDeliveredResponseRow } from "../../../../Services/messageCenterService/useListNotifications/useListNotifications.types";
// import { useCreateNotificationStore } from "../../ModalCreateNotification/store/createNotification";
// import { useNotificationsStore } from "../store/notifications-store";
import { NotificationsCardsContainer, NotificationStatusContainer } from "./NotificationsCardsStyle";

interface ApresentationNotificationsCardsProps {
  notifications?: GetNotificationsDeliveredResponseRow[]
}

export function ApresentationNotificationsCards({ notifications }: ApresentationNotificationsCardsProps) {
  // const { actions } = useNotificationsStore();
  // const { actions: createNotificationAction } = useCreateNotificationStore();

  // const categoryMap = {
  //   1: {
  //     title: "A-Class",
  //     description: "Informative",
  //   },
  //   2: {
  //     title: "B-Class",
  //     description: "Urgent",
  //   },
  // }

  return (
    <NotificationsCardsContainer>
      {notifications && notifications.map((notification) => (
        <GLCard.Provider key={notification.id}>
          <GLCard.Root>

            <GLCard.Header padding="18px 20px">
              <GLCard.Column width={35}>
                <GLTypography
                  text={notification.value.title}
                  as="p"
                  color="FONT_COLOR"
                  fontSize={4}
                  weight={600}
                />
              </GLCard.Column>

              <GLCard.Column width={18} direction="column" gap={5}>
                <GLTypography
                  text="Last time sent"
                  as="span"
                  color="FONT_COLOR_DARK_GREY"
                  fontSize={3}
                  weight={600}
                />

                <GLTypography
                  text={format(new Date(notification.value.sentAt), "MM/dd/yyyy")}
                  as="p"
                  color="FONT_COLOR"
                  fontSize={4}
                  weight={400}
                />
              </GLCard.Column>

              <GLCard.Column width={18} direction="column" gap={5}>
                <GLTypography
                  text="Created at"
                  as="span"
                  color="FONT_COLOR_DARK_GREY"
                  fontSize={3}
                  weight={600}
                />

                <GLTypography
                  text={format(new Date(notification.value.createdAt), "MM/dd/yyyy")}
                  as="p"
                  color="FONT_COLOR"
                  fontSize={4}
                  weight={400}
                />
              </GLCard.Column>

              <GLCard.Column width={18} direction="column" gap={5}>
                <GLTypography
                  text="Audience"
                  as="span"
                  color="FONT_COLOR_DARK_GREY"
                  fontSize={3}
                  weight={600}
                />

                <GLTypography
                  text={notification.value.devicesDelivered}
                  as="p"
                  color="FONT_COLOR"
                  fontSize={4}
                  weight={400}
                />
              </GLCard.Column>

              <GLCard.Column width={18}>
                <NotificationStatusContainer>
                  <GLTypography
                    text="Delivered"
                    as="p"
                    color="FONT_COLOR"
                    fontSize={3}
                    weight={600}
                  />
                </NotificationStatusContainer>
              </GLCard.Column>

            </GLCard.Header>

          </GLCard.Root>
        </GLCard.Provider>
      ))}
    </NotificationsCardsContainer>
  );
}
