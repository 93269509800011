import { GLCard, GLNotificationPreview, GLTypography } from "@group-link-one/grouplink-components";

import GovPIImage from "../../../../../images/MessageCenter/notifications/govpiLogo.webp"
import { useSegmentationStore } from "../../../ModalCreateNotification/store/segmentation";
import { useNotificationsStore } from "../../store/notifications-store";
import { NotificationMessage, PopupPreviewContentContainer } from "./PopupPreviewContentStyle";
import { usePopupPreviewNotification } from "./usePopupPreviewContent";

export function PopupPreviewContent() {
  const { androidPhoneImage, iphonePhoneImage } = usePopupPreviewNotification();
  const { state } = useNotificationsStore();
  const { state: segmentationState } = useSegmentationStore()

  return (
    <PopupPreviewContentContainer>
      <div className="popup-content">
        <div className="notification-content">
          <NotificationMessage>
            <GLTypography
              text="MESSAGE CONTENT & CATEGORY"
              as="p"
              color="FONT_COLOR_DARK_GREY"
              weight={600}
              fontSize={4}
            />

            <div className="notification-message-content">
              <div>
                <GLTypography
                  text="Title"
                  as="span"
                  fontSize={3}
                  color="FONT_COLOR_DARK_GREY"
                  weight={600}
                />
                <GLTypography
                  text={state.popupPreviewNotificationTitle || "Notification title"}
                  as="p"
                  fontSize={4}
                  color="FONT_COLOR"
                  weight={600}
                />
              </div>

              <div>
                <GLTypography
                  text="Message body"
                  as="span"
                  fontSize={3}
                  color="FONT_COLOR_DARK_GREY"
                  weight={600}
                />
                <GLTypography
                  text={state.popupPreviewNotificationMessage || "Notification message"}
                  as="p"
                  fontSize={4}
                  color="FONT_COLOR"
                  weight={400}
                  style={{ lineHeight: "24px" }}
                />
              </div>

              <div>
                <GLTypography
                  text="Category"
                  as="span"
                  fontSize={3}
                  color="FONT_COLOR_DARK_GREY"
                  weight={600}
                />

                <GLCard.Column direction="column" gap={0}>
                  <GLTypography
                    text={state.categoryTitle || "Category title"}
                    as="p"
                    fontSize={4}
                    color="FONT_COLOR"
                    weight={600}
                  />
                  <GLTypography
                    text={state.categoryDescription || "Category description"}
                    as="p"
                    fontSize={4}
                    color="FONT_COLOR"
                    weight={400}
                  />
                </GLCard.Column>

              </div>

              <div>
                <GLTypography
                  text="Location"
                  as="span"
                  fontSize={3}
                  color="FONT_COLOR_DARK_GREY"
                  weight={600}
                />

                <GLCard.Column direction="row" gap={5}>
                  <GLTypography
                    text={segmentationState.stateSelected || "Category title"}
                    as="p"
                    fontSize={4}
                    color="FONT_COLOR"
                    weight={600}
                  />
                  {"·"}
                  <GLTypography
                    text={segmentationState.municipalitiesSelected?.[0] || "Category description"}
                    as="p"
                    fontSize={4}
                    color="FONT_COLOR"
                    weight={400}
                  />
                </GLCard.Column>

              </div>
            </div>
          </NotificationMessage>
        </div>

        <GLNotificationPreview
          notificationImage={GovPIImage}
          notificationTitle={state.popupPreviewNotificationTitle || ""}
          notificationMessage={state.popupPreviewNotificationMessage || ""}
          androidImage={androidPhoneImage}
          iphoneImage={iphonePhoneImage}
        />
      </div>
    </PopupPreviewContentContainer>
  )
}
